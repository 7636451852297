const recordsData = [
	// {
	// 	id: 1,
	// 	firstName: 'Juan',
	// 	lastName: 'Dela Cruz',
	// 	email: 'juan.dlc@gmail.com',
	// 	contact: '09199294942',
	// },
];

export { recordsData };
